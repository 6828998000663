import React, { FC, useState } from 'react';
import { useFormikContext } from 'formik';
import { Aceleradores } from '~/assets/svg';
import { Amplify } from '~/services';
import { Path } from '~/routes/routes.path';
import { useStores } from '~/utils';
import {
  Body,
  Button,
  SectionForm,
  TextError,
  TextField,
  Title,
  Paragraph,
} from './styles';

const amplify = new Amplify();

const NewPasswordRequired: FC = (): JSX.Element => {
  const { routing } = useStores();

  const onLogin = () => {
    routing.push(Path.LOGIN);
  };

  const { values, errors, touched, submitForm, handleChange } =
    useFormikContext<User.ResetPasswordConfirm>();

  return (
    <Body>
      <SectionForm>
        <Aceleradores />
        <Title>Cadastrando Nova Senha</Title>

        <TextField
          name="newPassword"
          placeholder="Nova Senha"
          type="password"
          value={values.newPassword}
          onChange={handleChange('newPassword')}
        />

        <TextError>
          {touched.newPassword ? errors.newPassword : ''}&nbsp;
        </TextError>
        <Button onClick={submitForm}>Alterar Senha</Button>
        <Paragraph onClick={onLogin}>Voltar</Paragraph>
      </SectionForm>
    </Body>
  );
};

export default NewPasswordRequired;
