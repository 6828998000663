import forge from 'node-forge';

// Conteúdo da chave pública
const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqvlvkZFUQsNGCGmlsmOH
zulUy7q/Or5PtQQKEfLhkxh/pOwKcz2RyR8N3SaltMn94Jwfud2QlHmewq+4djJl
ceX0exMBw+6sEnAcHkBtTMYIM0Fr3IpnhsxWqWSO2Esa1xtSuo4E6ZgRfMaicvcg
HJ+s6zQNjz1R81NuEhgR5JI7NX6mmbDAAdsAWAI3URr/KNAWXQMb8zXCnIFNKpnw
rFHZlc/+YjQBm31Kmnrcwc0CocPO2HqrliuFSX+xA6wqydciObzXb5LPnrLwRFkV
j292M7c74VPHhJuL05ChP8niYMfn3iNCxAGXduofzskIN5auYs2vPLkDrLrRCVoy
PwIDAQAB
-----END PUBLIC KEY-----`;

// Conteúdo da chave privada
const PRIVATE_KEY = `-----BEGIN RSA PRIVATE KEY-----
MIIEogIBAAKCAQEAqvlvkZFUQsNGCGmlsmOHzulUy7q/Or5PtQQKEfLhkxh/pOwK
cz2RyR8N3SaltMn94Jwfud2QlHmewq+4djJlceX0exMBw+6sEnAcHkBtTMYIM0Fr
3IpnhsxWqWSO2Esa1xtSuo4E6ZgRfMaicvcgHJ+s6zQNjz1R81NuEhgR5JI7NX6m
mbDAAdsAWAI3URr/KNAWXQMb8zXCnIFNKpnwrFHZlc/+YjQBm31Kmnrcwc0CocPO
2HqrliuFSX+xA6wqydciObzXb5LPnrLwRFkVj292M7c74VPHhJuL05ChP8niYMfn
3iNCxAGXduofzskIN5auYs2vPLkDrLrRCVoyPwIDAQABAoIBAEnRYyW22OnwVILa
VkOEbITdwhIqAVl0BIbzhQOF2XXAbJAAp2xlB1YN9dbnUkERuu6oiZweeMdaET0e
UCacEKeR8LHYnXkCScG2LLW0/Fva3nx+wPqZuSP+gmYld/UTzDVWYywPmDhgwyAb
xNSdNPyWxjpQoZAYGhNUEKB4SvVjphhX/As0zGH8nxWZPyHt2JF806cl/LuV2NH6
Gmof4fviGjt3iaQJ8KFEx3VJlvftfirLmtS/TKlNHwnw4n5KrNVglwyWzR6myPCt
0X9G6wKfTIpZpcGzL3AB4RJeXvN7rlHYMs0B6UEeoa7ynyxE0EwjKZW5vRNA11Zv
JVyCKhUCgYEAv5SZmRHYiLkQ7wg3uC7Zd4CsOU+9UCOKc0RR9kQY3qGIGKs11y/d
f4AUgG5TJ5sN8b2bLx7JLDJS5hp8moiAZt5Dqp8EZBc5USWc865ZwjKzNT6SG8MP
Od+xzQ158hLR3SWVl3bXdVsbIO0R/kiaQTPSZxjodU+0kYvZ9qVfZX0CgYEA5HcL
nklzZ58zZYJTnv4YBBwGO2g6qhdKwK/i51geUUD1XrO2vMDqLrcsnjziHg9eR+Rk
NpwaTHhxynFvHlwcG5IaS99NDBsfUY7Ag7d24Suh6SyRSlbVHrw2MwfCbqaula1H
fPboXo54cuDJag/+Efx5koFI+IphDc3a8UZEk2sCgYADKbbneg3zI+sNpzsURWG9
wVgWkiR0AEI26PfZcwJeGpien6UB6n5jrn+oJyokS0ENrq2zN3Tmz+Xieql3+6zz
BdRkKr/tzxxZJJ/UvhopRKEEzgWng67qq7DJy+Eph/oDBq3TlFi2TUzDXGahph01
2l8s6JJd7XTzujVdBxnuGQKBgDE2Z31IKyDGDUcvZJ3Z9AhmlP0U/ZYIAGUzPfhB
Zf5wtKoaoiFUmN1JPcY0LSz2eIOZ8fBFffC8/R3zg1ER3EggF6QCTNFvwjZKPrPg
wC3OvUjB/eX6gFvnhPFHGZ3GL9M0CTMk6Hb9z3pHa3t6SGtNbE6fpL1M6Y0Uq7XP
QhRTAoGAE9NpTb+h4Hy1LuLnPvmspnNAAo1C/spLGNZU9L2l6yF+/poXEg9tdWez
07NnKS4TD9YVDWoBRVXBhooMdXNvX4oxTZfYZq/ZYSHZTrApmQbsF1UPfL4pbveZ
xiVUhboWwlvpXMAvqNpQyZ64HwcMxFrReS0fvTHGFzzyIfDbYRw=
-----END RSA PRIVATE KEY-----`;


/**
 * Gera uma chave AES aleatória.
 * @returns A chave AES gerada.
 */
const generateAESKey = (): string => {
    const key = forge.random.getBytesSync(32); 
    return forge.util.encode64(key);
};

/**
 * Criptografa os dados usando AES.
 * @param data O texto que você quer criptografar.
 * @param key A chave AES em base64.
 * @returns O texto criptografado em base64.
 */
const encryptAES = (data: string, key: string): string => {
    const keyBytes = forge.util.decode64(key);
    const cipher = forge.cipher.createCipher('AES-GCM', keyBytes);
    const iv = forge.random.getBytesSync(12); 
    cipher.start({ iv });
    cipher.update(forge.util.createBuffer(data, 'utf8'));
    cipher.finish();
    const encrypted = cipher.output.bytes();
    const tag = cipher.mode.tag.bytes();
    return forge.util.encode64(iv + encrypted + tag); 
};

/**
 * Criptografa os dados usando uma chave pública RSA e AES.
 * @param data O texto que você quer criptografar.
 * @returns Um objeto com a chave AES criptografada e os dados criptografados.
 */
export const encryptData = (data: string): string => {
    const publicKey = forge.pki.publicKeyFromPem(PUBLIC_KEY);
    const aesKey = generateAESKey();
    const encryptedKey = publicKey.encrypt(forge.util.decode64(aesKey), 'RSA-OAEP', {
        md: forge.md.sha256.create(),
    });
    
    const encryptedData = encryptAES(data, aesKey);

    const jsonObjetive = { 
        encryptedKey: forge.util.encode64(encryptedKey), 
        encryptedData
    };

    const jsonStr = JSON.stringify(jsonObjetive);
    
    // Converte a string JSON para bytes em UTF-8
    const jsonBytes = new TextEncoder().encode(jsonStr);
    // Codifica os bytes para Base64
    const base64Str = btoa(String.fromCharCode(...jsonBytes));
    return base64Str;
};

/**
 * Descriptografa os dados usando uma chave privada RSA e AES.
 * @param encryptedObject O objeto criptografado em base64.
 * @returns O texto descriptografado.
 */
export const decryptData = (encryptedObject: string): string => {
    // Converte a string Base64 para bytes em UTF-8
    const jsonStr = forge.util.decode64(encryptedObject);
    const { encryptedKey, encryptedData } = JSON.parse(jsonStr);
    const privateKey = forge.pki.privateKeyFromPem(PRIVATE_KEY);
    const decodedKey = forge.util.decode64(encryptedKey);
    const aesKey = privateKey.decrypt(decodedKey, 'RSA-OAEP', {
        md: forge.md.sha256.create(),
    });

    const encryptedBytes = forge.util.decode64(encryptedData);

    const iv = encryptedBytes.slice(0, 12);
    const ciphertext = encryptedBytes.slice(12, -16);
    const tag = encryptedBytes.slice(-16);
    
    const decipher = forge.cipher.createDecipher('AES-GCM', aesKey);
    decipher.start({ iv: forge.util.createBuffer(iv), tag: forge.util.createBuffer(tag) });
    decipher.update(forge.util.createBuffer(ciphertext));
    const success = decipher.finish();

    if (!success) {
        throw new Error('Falha na descriptografia.');
    }

    return decipher.output.toString();
};

