import React, { useEffect, useState } from 'react';
import { useStores } from '~/utils';
import { Card, Input } from 'antd'; 
import './style.css'; // Importando o CSS
import { saveTenantId, setTenantIdCookie, setTenantNameCookie } from '~/utils/tenant'; 
import { observer } from 'mobx-react';
import Header from '~/components/Header'; 

const { Search } = Input;

const GroupSelection = () => {
    const { user } = useStores();
    const [filteredGroups, setFilteredGroups] = useState([]);
    const [loadingMessage, setLoadingMessage] = useState('Loading...');

    useEffect(() => {
        user.fetchUserTenants();
        const timer = setTimeout(() => {
            if (user.tenants.length === 0) {
                setLoadingMessage('Você não está associado a nenhum Workspace. Entre em contato com a equipe de desenvolvimento.');
            }
        }, 10000); // 10 segundos

        return () => clearTimeout(timer);
    }, [user.tenants]);

    const handleTenantSelect = (tenant) => {
        const tenantId = tenant.tenant_id;
        const tenantName = tenant.tenant_name;
        if (tenantId) {
            saveTenantId(tenantId);
            setTenantIdCookie(tenantId, 7); 
            setTenantNameCookie(tenantName, 7); 
        }
        window.location.href = '/'; 
    };

    const handleSearch = (value) => {
        const filtered = user.tenants.filter(tenant =>
            tenant.tenant_name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredGroups(filtered);
    };

    return (
        <div className="group-selection">
            <Header /> {}
            <div className="header">
                <h1>Selecione seu Workspace</h1>
                <p>Você pode alterar seu Workspace a qualquer momento.</p>
                <Search
                    className="search-bar"
                    placeholder="Find a space"
                    onSearch={handleSearch}
                    enterButton
                    style={{ maxWidth: 400, margin: '20px auto' }}
                />
            </div>
            <div className="group-list">
                {user.tenants.length > 0 ? (
                    user.tenants.map((tenant) => (
                        <div key={tenant.tenant_id} className="group-card-link" onClick={() => handleTenantSelect(tenant)}>
                            <Card
                                className="group-card"
                                cover={<img alt={tenant.tenant_name} src={`data:image/png;base64,${tenant.logo_img_base64}`} />}
                            >
                                <div className="card-content">
                                    <div className="card-title">{tenant.tenant_name}</div>
                                </div>
                            </Card>
                        </div>
                    ))
                ) : (
                    <div className="no-workspace-message">
                        {user.loading ? (
                            <div>{loadingMessage}</div>
                        ) : (
                            <div>
                                Você não está associado a nenhum Workspace. Entre em contato com a equipe de desenvolvimento.
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default observer(GroupSelection);