import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { Path } from '~/routes/routes.path';
import { Amplify } from '~/services';
import { alert, useStores, yup } from '~/utils';
import NewPasswordRequired from './NewPasswordRequired';
import { replace } from 'react-router-dom';
import { UserInfOutput } from '~/utils/types/user';
import { saveTenantId, setTenantIdCookie } from '~/utils/tenant';

type Props = {};

const validate = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, 'A senha deve ter pelo menos 8 caracteres')
    .matches(/[A-Z]/, 'A senha deve conter pelo menos uma letra maiúscula')
    .matches(/[0-9]/, 'A senha deve conter pelo menos um número')
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      'A senha deve conter pelo menos um caractere especial',
    )
    .required('Senha é obrigatória'),
});

const amplify = new Amplify();

const NewPasswordContainer: FC<Props> = () => {
  const { routing, user } = useStores();

  const onNewPasswordRequired = async (newPassword: User.ComfirmSignIn) => {
    const changed = await amplify.completeNewPassword(newPassword.newPassword);
    if (changed) {
      alert({
        message: 'Senha alterada com sucesso',
        type: 'success',
        position: 'bottom-center',
      });

      const userInfo: UserInfOutput = await amplify.userInfo();

      user.onSetUserData(userInfo);

      // Armazena o tenant_id no localStorage e cookie, se existir
      const tenantId = userInfo?.tenant_id;
      if (tenantId) {
        saveTenantId(tenantId);
        setTenantIdCookie(tenantId, 5); // Armazena por 5 dias, ver com o time quantos dias necessario,
      }
      routing.push(Path.SELECT_GROUP);
    } else {
      alert({
        message: 'Erro ao alterar senha',
        type: 'error',
        position: 'bottom-center',
      });
    }
  };

  return (
    <Formik
      onSubmit={onNewPasswordRequired}
      validationSchema={validate}
      initialValues={{ newPassword: ''}}
    >
      <NewPasswordRequired />
    </Formik>
  );
};

export default NewPasswordContainer;
