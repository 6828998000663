import styled, {css} from 'styled-components';
import { Table } from 'antd';

export const ContainerTable = styled.div`
display: block !important;
width: 100%;
`;

export const StyledModalTable = styled(Table)`
.ant-spin-container {      
  display: block !important;
  width: 100%;
}
`;

// Container principal do Orquestrador
export const OrquestradorContainer = styled.div`
  background-color: #081e38;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 20px;
  padding: 20px;
  
`;

// Container para a tabela
export const TableContainer = styled.div`
  background-color: #081e38;
  border-radius: 8px;
  overflow-x: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  & div {
    display: block !important;
  }
`;

// Estilo customizado para a tabela do Ant Design
export const StyledTable = styled(Table)`
  width: 100%;
  
  .ant-table-thead > tr > th {
    background-color: #102a44;
    color: #fff;
    text-align: center;
  }

  .ant-table-tbody > tr > td {
    background-color: #081e38;
    color: #72b2f2;
    text-align: center;
  }

  .ant-table-placeholder {
    background-color: #081e38;
    color: #fff;
  }

  .modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  flex: 1; 
}

.table-pagination {
  margin-top: 20px;
}

.historico-list {
  overflow-y: auto; 
}

.pagination {
  /* Estilos da paginação */
  margin-top: auto; 
}
`;