import { Input, Button, Form, Checkbox, message } from 'antd';
import { updateTask, createTask } from '~/services/api/taskService';
import { alert } from '~/utils';

const EditTaskModal = ({ tenantId, setIsModalVisible, task = null }) => {
    const [form] = Form.useForm();

    const handleSave = async (values) => {
        try {
            const cronValue = typeof values.cron === 'string' ? values.cron : '';
            const cronList = cronValue
                .split(',')
                .map(item => item.trim().replace(/['"]/g, ''))
                .filter(item => item);
    
            if (task === null) {
                await createTask(
                    tenantId,
                    values.name,
                    values.command,
                    cronList
                );
                alert({
                    message: 'Tarefa criada com sucesso!',
                    type: 'success',
                    position: 'bottom-center',
                });
            } else {
                await updateTask(
                    tenantId,
                    task.x_api_key,
                    task.id,
                    values.name,
                    values.command,
                    cronList,
                    values.enabled
                );
                alert({
                    message: 'Tarefa atualizada com sucesso!',
                    type: 'success',
                    position: 'bottom-center',
                });
            }
            setIsModalVisible(false);
        } catch (error) {
            alert({
                message: task === null ? 'Não foi possível criar a tarefa!' : 'Não foi possível atualizar a tarefa!',
                type: 'error',
                position: 'top-center',
            });
        }
    };

    return (
        <div style={{ display: 'block' }}>
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    name: task ? task.name : '',
                    command: task ? task.command : '',
                    cron: task ? task.cron : '',
                    enabled: task ? task.enabled : true,
                }}
                onFinish={handleSave}
                style={{ display: 'block', width: '40vw' }}
            >
                <Form.Item
                    label="Nome da Tarefa"
                    name="name"
                    rules={[{ required: true, message: 'Por favor, insira o nome da tarefa' }]}
                    style={{ display: 'block' }}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Comando"
                    name="command"
                    rules={[{ required: true, message: 'Por favor, insira o comando da tarefa' }]}
                    style={{ display: 'block' }}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Cron (digite os valores separados por vírgula)"
                    name="cron"
                    rules={[{ required: true, message: 'Por favor, insira o cron' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="enabled"
                    valuePropName="checked"
                >
                    <Checkbox>Habilitar Tarefa</Checkbox>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" onClick={() => form.submit()}>Salvar</Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default EditTaskModal;
