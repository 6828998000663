import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '~/utils';
import { Button, TextField, Switch, FormControlLabel } from '@material-ui/core';
import { Add, Delete, Visibility, VisibilityOff } from '@material-ui/icons';
import Header from '~/components/Header';
import { encryptData } from '~/utils/encryptionUtils';
import './style.css';
import { ButtonDelete, ButtonAdd, ButtonShow } from './styles';

const TenantConfig = () => {
  const { user } = useStores();
  const [isJiraEnabled, setIsJiraEnabled] = useState(false);
  const [showToken, setShowToken] = useState(false);
  const [jiraConfig, setJiraConfig] = useState({
    url: '',
    email: '',
    token: '',
    projects_keys: [],
  });
  const [error, setError] = useState('');

  useEffect(() => {
    const tenantId = localStorage.getItem('tenant_id');
    if (tenantId) {
      user.fetchConfig(tenantId).then(config => {
        if (config?.jira_cfg) {
          setJiraConfig(config.jira_cfg);
          setIsJiraEnabled(config.jira_cfg.activated);
        }
      });
    }
  }, [user]);

  const handleSave = async () => {
    if (!jiraConfig.url || !jiraConfig.email || !jiraConfig.token || !jiraConfig.projects_keys) {
      setError('Todos os campos são obrigatórios.');
      return;
    }

    const encryptedConfig = {
      ...jiraConfig,
      email: encryptData(jiraConfig.email),
      token: encryptData(jiraConfig.token),
    };

    try {
      await user.saveTenantConfig({ jira_cfg: { ...encryptedConfig, activated: isJiraEnabled } });
      alert('Configuração salva com sucesso!');
      setError('');
    } catch (error) {
      console.error('Erro ao salvar configuração:', error);
    }
  };

  const handleChange = (field: string, value: any) => {
    setJiraConfig({ ...jiraConfig, [field]: value });
  };

  const toggleJiraEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsJiraEnabled(event.target.checked);
  };

  return (
    <div className="tenant-config">
      <Header showConfig={false} pageTitle="Configuração do Workspace" />
      <div className="config-content">
        <div className="jira-toggle">
          <div>Integração com o JIRA</div>
          <FormControlLabel
            control={
              <Switch
                checked={isJiraEnabled}
                onChange={toggleJiraEnabled}
                color="primary"
              />
            }
            label=""
          />
        </div>

        {isJiraEnabled && (
            <div className="jira-config">
            <TextField
              label="Jira URL"
              value={jiraConfig.url}
              onChange={(e) => handleChange('url', e.target.value)}
              fullWidth
              required
            />
            <TextField
              style={{ marginTop: '10px' }}
              label="Jira Email"
              value={jiraConfig.email}
              onChange={(e) => handleChange('email', e.target.value)}
              fullWidth
              required
            />
            <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
              <TextField
              label="Jira Token"
              type={showToken ? 'text' : 'password'}
              value={jiraConfig.token}
              onChange={(e) => handleChange('token', e.target.value)}
              fullWidth
              required
              />
                <ButtonShow
                onClick={() => setShowToken(!showToken)}
                color="primary"
                style={{ marginLeft: '10px' }}
                >
                {showToken ? <VisibilityOff /> : <Visibility />}
                </ButtonShow>
            </div>
            {jiraConfig.projects_keys.map((key, index) => (
              <div key={index} style={{ height: '60px', marginTop: '10px', display: 'flex', alignItems: 'center'}}>
              <TextField
                label={`Projeto ${index + 1}`}
                value={key}
                onChange={(e) => {
                const updatedProjects = [...jiraConfig.projects_keys];
                updatedProjects[index] = e.target.value;
                handleChange('projects_keys', updatedProjects);
                }}
                fullWidth
                required
                />
                {jiraConfig.projects_keys.length > 1 && (
                <ButtonDelete
                  onClick={() => {
                  const updatedProjects = jiraConfig.projects_keys.filter((_, i) => i !== index);
                  handleChange('projects_keys', updatedProjects);
                  }}
                  color="secondary"
                  style={{ marginLeft: '10px' }}
                  >
                  <Delete/>
                </ButtonDelete>
                )}
              </div>
            ))}
            <div style={{ height: '30px', marginTop: '5px', display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
              <ButtonAdd
              onClick={() => {
                handleChange('projects_keys', [...jiraConfig.projects_keys, '']);
              }}
              color="primary"
              >
              <Add/>
              </ButtonAdd>
            </div>
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              style={{ marginTop: '10px' }}
            >
              Salvar
            </Button>
            {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
            </div>
        )}
      </div>
    </div>
  );
};

export default observer(TenantConfig);