import { getTheme, pxToRem, styled } from '~/utils';

const primaryMain = getTheme('primary.main');
const primaryLight = getTheme('primary.light');

export const ButtonDelete = styled.button`
    cursor: pointer;
    font-weight: 600;
    color: ${primaryLight};
    font-size: ${pxToRem(16)};
    border: 0;
    border-radius: ${pxToRem(5)};
    width: 20%;
    height: ${pxToRem(48)};
    background-color: ${primaryMain}};
`;

export const ButtonAdd = styled.button`
    cursor: pointer;
    font-weight: 600;
    color: ${primaryLight};
    font-size: ${pxToRem(16)};
    border: 0;
    border-radius: ${pxToRem(5)};
    width: 50%;
    height: ${pxToRem(30)};
    background-color: #F46E0B;
`;

export const ButtonShow = styled.button`
    cursor: pointer;
    font-weight: 600;
    color: ${primaryLight};
    font-size: ${pxToRem(16)};
    border: 0;
    border-radius: ${pxToRem(5)};
    width: 20%;
    height: ${pxToRem(48)};
    background-color: ${primaryMain};
`;