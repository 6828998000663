import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { Path } from '~/routes/routes.path';
import { Amplify, Storage } from '~/services';
import { cipherText, useStores, yup, AMPLIFY_ENUM, alert } from '~/utils';
import Login from './Login';
import { saveTenantId, setTenantIdCookie } from '~/utils/tenant';
import { UserInfOutput } from '~/utils/types/user'; // Importe o tipo correto

type Props = {};

const validate = yup.object().shape({
  email: yup.string().email('Email inválido').required('Email é obrigatório'),
  password: yup.string().required('Senha é obrigatória'),
});

const amplify = new Amplify();

const LoginContainer: FC<Props> = () => {
  const { routing, user } = useStores();

  const onLogin = async (data: User.SignIn) => {
    const logged = await amplify.signIn(data);


    if (logged?.isSignedIn) {
      const userInfo: UserInfOutput = await amplify.userInfo();

      user.onSetUserData(userInfo);

      // Armazena o tenant_id no localStorage e cookie, se existir
      const tenantId = userInfo?.tenant_id;
      if (tenantId) {
        saveTenantId(tenantId);
        setTenantIdCookie(tenantId, 5); // Armazena por 5 dias, ver com o time quantos dias necessario,
      }

      // routing.replace(Path.HOME);
      routing.replace('/select-group');
    }else if (logged?.nextStep.signInStep === AMPLIFY_ENUM.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED) {
      alert({
        message: 'Você precisa alterar a senha',
      })
      routing.push(Path.NEW_PASSWORD_REQUIRED, { email: data.email, currentSession: logged });
    } else if (logged?.nextStep.signInStep === AMPLIFY_ENUM.USER.CONFIRM_SIGN_UP) {
      alert({
        message: 'Conta pendente de confirmação, você será redirecionado para confirmar a conta',
      });
      const send = await amplify.resendCode({ email: data.email }).catch(
        (error) => {
          setTimeout(() => {
            if (error.message === 'Attempt limit exceeded, please try after some time.') {
              alert({
                message: 'Limite de tentativas atingido, tente novamente mais tarde',
                type: 'error',
                position: 'bottom-center',
              });
            } else {
              alert({
                message: error,
                type: 'error',
                position: 'bottom-center',
              });
            }
          }, 7000);
        }
      );
      if (send !== undefined) {
        setTimeout(() => {
          alert({
            message: 'Código de validação enviado',
            type: 'info',
            position: 'bottom-center',
          });
          routing.push(Path.VERIFY_ACCOUNT, { username: data.email });
        }, 7000);
      }
    }
  };

  useEffect(() => {
    amplify.verifyUser();
  }, []);

  return (
    <Formik
      onSubmit={onLogin}
      validationSchema={validate}
      initialValues={{ email: '', password: '' }}
    >
      <Login />
    </Formik>
  );
};

export default LoginContainer;
