import React, { FC, useState, useEffect } from 'react';
import { Menu, MenuItem, Toolbar } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import { PrimeLogon } from '~/assets/img';
import { Path } from '~/routes/routes.path';
import { device } from '~/theme/breakpoints';
import { useStores } from '~/utils';
import If from '../If';
import {
  Content,
  IconStyled,
  ImgIcon,
  NameUser,
  SectionIcon,
  SectionLogo,
  SectionToolbar,
  TextProduct,
} from './styles';
import { observer } from 'mobx-react';
import { Amplify, Storage } from '~/services';
import { cleanTenantCookie } from '~/utils/tenant';

const amplify = new Amplify();

// Define a interface para as props
interface HeaderProps {
  showConfig?: boolean;
  pageTitle?: string;
}

const Header: FC<HeaderProps> = ({ showConfig = true, pageTitle }) => {
  const { routing, user } = useStores();
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const [tenantName, setTenantName] = useState('');

  useEffect(() => {
    const tenantNameFromCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('tenant_name='))
      ?.split('=')[1];
    if (tenantNameFromCookie) {
      setTenantName(tenantNameFromCookie);
    }
  }, []);

  const onLogout = async () => {
    await amplify.logout();
    Storage.clearStorage();
    cleanTenantCookie();
    routing.replace(Path.LOGIN);
  };

  const onHome = () => {
    routing.replace(Path.HOME);
  };

  const onBackToSelection = () => {
    cleanTenantCookie();
    routing.replace('/select-group');
  };

  const onConfig = () => {
    routing.replace('/config');
  };

  const isAdmin = user.tenantLevel === 1;

  return (
    <Content>
      <SectionLogo>
        <ImgIcon src={PrimeLogon} title="Home" onClick={onHome} />
        <TextProduct>
          Portal de Aceleradores e IA 
          {tenantName && ` | ${tenantName}`}
          {pageTitle && ` | ${pageTitle}`}
        </TextProduct>
      </SectionLogo>

      <SectionToolbar>
        <SectionIcon onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            setAnchorEl(event.currentTarget as unknown as HTMLButtonElement)}>
          <If condition={!device.isMobile}>
            <NameUser>{user.data?.username}</NameUser>
          </If>
          <IconStyled>expand_more</IconStyled>
        </SectionIcon>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{ marginTop: '4px' }}
        >
          {tenantName && isAdmin && (
            <MenuItem
              style={{ justifyContent: 'center' }}
              onClick={() => {
                onConfig();
                setAnchorEl(null);
            }}>
              Configuração
            </MenuItem>
          )}
          {tenantName && (
            <MenuItem
              style={{ justifyContent: 'center' }}
              onClick={() => {
                onBackToSelection();
                setAnchorEl(null);
            }}>
              Trocar Workspace
            </MenuItem>
          )}
          <MenuItem
            style={{ justifyContent: 'center' }}
            onClick={() => {
              onLogout();
              setAnchorEl(null);
          }}>
            Sair
            <ExitToApp style={{ marginLeft: '8px' }} />
          </MenuItem>
        </Menu>
      </SectionToolbar>
    </Content>
  );
};

export default observer(Header);