import { RequestOrchestrator } from '~/services';

const BASE_URL = process.env.REACT_APP_API_ORCHESTRATOR;

// Função para obter as tarefas
export const getTasks = async (tenantId: string) => {
  try {
    const response = await RequestOrchestrator.get(`/task/${tenantId}`);
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar tarefas:', error);
    throw error;
  }
};

// Função para obter o histórico de uma tarefa
export const getTaskHistory = async (tenantId: string, taskId: string) => {
  try {
    const response = await RequestOrchestrator.get(`/task/${tenantId}/${taskId}/history`);
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar histórico da tarefa:', error);
    throw error;
  }
};

// Função para obter o nome do job
export const getJobName = async (tenantId: string, taskId: string) => {
  try {
    const response = await RequestOrchestrator.get(`/task/${tenantId}/${taskId}`);
    return response.data.job_name; // Supondo que a resposta contenha 'job_name'
  } catch (error) {
    console.error('Erro ao buscar o nome do job:', error);
    throw error;
  }
};

// Função para obter as dependências de uma tarefa (tasks filhas)
export const getTaskDependencies = async (tenantId: string, taskId: string) => {
  try {
    const response = await RequestOrchestrator.get(`/task/${tenantId}/${taskId}/dependencies`);
    return response.data; // Supondo que o backend retorne as tasks dependentes
  } catch (error) {
    console.error('Erro ao buscar dependências da tarefa:', error);
    throw error;
  }
};

// Função para criar uma tarefa
export const createTask = async (tenantId: string, name: string, command: string, cron: string[]) => {
  try {
    const taskData = {
      "x_api_key": 'x-api-key',
      "name": name,
      "command": command,
      "cron": cron,
      "enabled": true
    };
    const response = await RequestOrchestrator.post(`/task/${tenantId}`, taskData);
    return response.data;
  } catch (error) {
    console.error('Erro ao criar tarefa:', error);
    throw error;
  }
};

// Função para alterar uma tarefa
export const updateTask = async (tenantId: string, x_api_key: string, taskId: string, name: string, command: string, cron: string[], enabled: boolean) => {
  try {
    const taskData = {
      "x_api_key": x_api_key,
      "name": name,
      "command": command,
      "cron": cron,
      "enabled": enabled
    };
    const response = await RequestOrchestrator.put(`/task/${tenantId}/${taskId}`, taskData);
    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar tarefa:', error);
    throw error;
  }
};

// Função para deletar uma tarefa
export const deleteTask = async (tenantId: string, taskId: string) => {
  try {
    const response = await RequestOrchestrator.delete(`/task/${tenantId}/${taskId}`);
    return response.data;
  } catch (error) {
    console.error('Erro ao deletar tarefa:', error);
    throw error;
  }
};

// Função para parar uma tarefa
export const breakTask = async (tenantId: string, taskId: string) => {
  try {
    await RequestOrchestrator.post(`/task/${tenantId}/${taskId}/kill`);
    return true;
  } catch (error) {
    if (error.response.data.message === "Task is not running!") {
      return false;
    }
    console.error('Erro ao parar tarefa:', error);
  }
};

// Função para executar uma tarefa
export const runTask = async (tenantId: string, taskId: string) => {
  try {
    const response = await RequestOrchestrator.post(`/scheduler/${tenantId}/${taskId}`);
    return response.data;
  } catch (error) {
    console.error('Erro ao executar tarefa:', error);
    throw error;
  }
};

// Função para criar dependências de tarefas
export const setTaskDependencies = async (tenantId: string, taskId: string, parentTaskId: string) => {
  try {
    const response = await RequestOrchestrator.patch(`/task/${tenantId}/${taskId}/dependency/${parentTaskId}`);
    return response.data;
  } catch (error) {
    console.error('Erro ao criar dependências entre tarefas:', error);
    throw error;
  }
};
