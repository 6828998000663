import React, { useEffect, useState } from 'react';
import { Button, Layout, Dropdown, MenuProps, Space, message } from 'antd';
import { HistoryOutlined, EditOutlined, DeleteOutlined, PauseCircleOutlined, PlayCircleOutlined, PoweroffOutlined, MoreOutlined } from '@ant-design/icons';
import HeaderPrime from '~/components/Header';
import MenuVertical from '~/components/Menu';
import { OrquestradorContainer, TableContainer, StyledTable, StyledModalTable } from './styles';
import cronstrue from 'cronstrue/i18n';
import CustomModal from '~/components/CustomModal';
import { getTasks, getTaskHistory, getTaskDependencies, updateTask, deleteTask, breakTask, runTask } from '~/services/api/taskService';
import parser from 'cron-parser';
import moment from 'moment';
import 'moment/locale/pt-br';
import { getTenantId } from '~/utils/tenant';
import EditTaskModal from '~/components/EditTaskModal';


const OrquestradorRPA = () => {
  const { Header, Footer, Sider, Content } = Layout;
  const [jobs, setJobs] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [isLogVisible, setIsLogVisible] = useState<number | null>(null);
  const [isLogModalVisible, setIsLogModalVisible] = useState(false);
  const [logContent, setLogContent] = useState('');
  const [selectedTask, setSelectedTask] = useState<number | null>(null);
  const [tasks, setTasks] = useState([]);
  const tenantId = getTenantId();


  useEffect(() => {
    if (tenantId) {
      fetchJobs();
    } else {
      console.error('Tenant ID não encontrado.');
    }
  }, [tenantId]);

  const fetchJobs = async () => {
    try {
      const tasks = await getTasks(tenantId);
      setTasks(tasks);

      const formattedJobs = tasks.map((task) => {
        const parentTask = task.parent_tasks && task.parent_tasks.length > 0 ? task.parent_tasks[0] : null;

        const periodicidade = formatCron(task.cron, parentTask);
        const nextExecution = formatNextExecution(task.cron, task.last_execution?.start_time, parentTask);

        return {
          id: task.id,
          host: task.last_execution?.host || 'Desconhecido',
          name: task.name,
          periodicidade,
          lastRun: task.last_execution?.start_time
            ? moment(task.last_execution.start_time).format('DD/MM/YYYY HH:mm:ss')
            : 'Nunca',
          nextExecution,
          currentStatus: translateStatusToPT(task.last_execution?.status),
          agendamento: task.enabled ? 'Ativo' : 'Desativado',
        };
      });

      setJobs(formattedJobs);
    } catch (error) {
      console.error('Erro ao buscar tarefas:', error);
    }
  };

  const formatCron = (cron: string | string[], parentTask: any) => {
    if (!cron || cron.length === 0) {
      if (parentTask) {
        return `Executa após ${parentTask.name}`;
      }
      return 'Sem periodicidade definida';
    }

    if (Array.isArray(cron)) {
      return cron.map((expression) => translateCronToPT(expression)).join(', ');
    }

    return translateCronToPT(cron);
  };

  const formatNextExecution = (cron: string | string[], lastRun: string | null, parentTask: any) => {
    if (!cron || cron.length === 0) {
      if (parentTask) {
        return `Executa após ${parentTask.name}`;
      }
      return 'Indisponível';
    }

    if (Array.isArray(cron)) {
      const nextExecutions = cron.map((expression) => calculateNextExecution(lastRun, expression));
      //return thelowest date
      return nextExecutions.reduce((a, b) => (a < b ? a : b));
      // return cron
      //   .map((expression) => calculateNextExecution(lastRun, expression))
      //   .join(', ');
    }

    // return calculateNextExecution(lastRun, cron);
  };

  const translateCronToPT = (cronExpression: string) => {
    try {
      return cronstrue.toString(cronExpression, { locale: 'pt_BR' });
    } catch (err) {
      console.error('Erro na tradução da expressão CRON:', err);
      return 'Erro na tradução do cron';
    }
  };

  const calculateNextExecution = (lastRun: string | null, cronExpression: string) => {
    if (!lastRun || !cronExpression) return 'Indisponível';

    try {
      const interval = parser.parseExpression(cronExpression, {
        currentDate: new Date(lastRun),
      });
      return moment(interval.next().toString()).format('DD/MM/YYYY HH:mm:ss');
    } catch (err) {
      console.error('Erro ao calcular a próxima execução:', err);
      return 'Erro no cálculo';
    }
  };

  const translateStatusToPT = (status: string) => {
    switch (status) {
      case 'success':
        return 'Concluído';
      case 'running':
        return 'Em execução';
      case 'error':
        return 'Falhou';
      case 'pending':
        return 'Pendente';
      case 'canceled':
        return 'Cancelado';
      case 'killed':
        return 'Interrompido';
      default:
        return 'Desconhecido';
    }
  };

  const showModal = async (jobId: number) => {
    try {
      const history = await getTaskHistory(tenantId, jobId.toString());
      const data = history.map(h => {
        const startTime = moment(h.start_time);
        const endTime = h.end_time ? moment(h.end_time) : null;
        const duration = endTime ? moment.duration(endTime.diff(startTime)) : null;
        const formattedDuration = duration
          ? `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`
          : 'Em andamento';

        return {
          id: h.id,
          start_time: startTime.format('DD/MM/YYYY HH:mm:ss'),
          end_time: endTime ? endTime.format('DD/MM/YYYY HH:mm:ss') : 'Em andamento',
          status: translateStatusToPT(h.status),
          duration: formattedDuration,
          log: h.logs ? h.logs.join('\n') : 'Log não disponível',
        };
      });

      const columns = [
        { title: 'Início', dataIndex: 'start_time', key: 'start_time' },
        { title: 'Fim', dataIndex: 'end_time', key: 'end_time' },
        { title: 'Status', dataIndex: 'status', key: 'status' },
        { title: 'Duração', dataIndex: 'duration', key: 'duration' },
        {
          title: 'Log',
          dataIndex: 'log',
          key: 'log',
          render: (_, record) => (
            <div>
              <Button onClick={() => showLogModal(record.log)}>
                Exibir Log
              </Button>
            </div>
          ),
        },
      ];

      setModalTitle('Histórico da Tarefa');
      setModalContent(
        <div>
          <StyledModalTable
            dataSource={data}
            columns={columns}
            rowKey="id"
            pagination={{ pageSize: 5, position: ['bottomCenter'], showSizeChanger: false }}
          />
        </div>
      );

      setIsModalVisible(true);
    } catch (error) {
      console.error('Erro ao buscar histórico da tarefa:', error);
    }
  };


  // Função para exibir o modal de dependências (apenas o nome da tarefa)
  const showDependenciesModal = async (jobId: number) => {
    try {
      const dependencies = await getTaskDependencies(tenantId, jobId.toString());

      const columns = [
        { title: 'Nome da Tarefa', dataIndex: 'name', key: 'name' },
      ];

      const data = dependencies.map(dep => ({
        name: dep.name,
      }));

      setModalTitle('Dependências da Tarefa');
      setModalContent(
        <div>
          <StyledModalTable
            dataSource={data}
            columns={columns}
            rowKey="name"
            pagination={{ pageSize: 5, position: ['bottomCenter'], showSizeChanger: false }}
          />
        </div>
      );

      setIsModalVisible(true);
    } catch (error) {
      console.error('Erro ao buscar dependências da tarefa:', error);
    }
  };

  const showLogModal = (log: string) => {
    setLogContent(log);
    setIsLogModalVisible(true);
  };

  const handleLogModalCancel = () => {
    setIsLogModalVisible(false);
    setLogContent('');
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalContent(null);
  };

  const editTask = async (task) => {
    setModalTitle('Editar Tarefa');
    setModalContent(
      <div>
        <EditTaskModal
          task={task}
          tenantId={tenantId}
          setIsModalVisible={setIsModalVisible}>
        </EditTaskModal>
      </div>
    );

    setIsModalVisible(true);
    await fetchJobs();
  };

  const disableTask = async (record) => {
    const task = tasks.find(dicionario => dicionario.id === record.id);
    if (task) {
      try {
        await updateTask(tenantId, task.x_api_key, task.id, task.name, task.command, task.cron, !task.enabled);
        if (!task.enabled) {
          message.success(`Tarefa "${task.name}" habilitada com sucesso`);
        } else {
          message.success(`Tarefa "${task.name}" desabilitada com sucesso`);
        }
        fetchJobs();
      } catch (error) {
        message.error(`Erro ao habilitar/desabilitar a tarefa "${task.name}"`);
      }
    }
  };

  const breakJob = async (taskId, taskName) => {
    try {
      const success = await breakTask(tenantId, taskId);
      if (success) {
        message.success(`Execução da tarefa "${taskName}" parada com sucesso`);
      } else {
        console.error('Tarefas que não estão com execução ativa não podem ser paradas');
        message.error(`Tarefa "${taskName}" não está em execução`);
      }
      fetchJobs();
    } catch (error) {
      message.error(`Erro ao parar a execução da tarefa "${taskName}"`);
    }
  };

  const deleteJob = async (taskId, taskName) => {
    try {
      await deleteTask(tenantId, taskId);
      message.success(`Tarefa "${taskName}" deletada com sucesso`);
      fetchJobs();
    } catch (error) {
        message.error(`Erro ao deletar a tarefa "${taskName}"`);
    }

  };

  const runJob = async (taskId, taskName) => {
    try {
      const response = await runTask(tenantId, taskId);
      message.success(`Tarefa "${taskName}" iniciada com sucesso`);
      fetchJobs();
    } catch (error) {
        message.error(`Erro ao iniciar a tarefa "${taskName}"`);
    }
  };

  const columns = [
    { title: 'Nome do Job', dataIndex: 'name', key: 'name' },
    { title: 'Host', dataIndex: 'host', key: 'host' },
    { title: 'Agendamento', dataIndex: 'agendamento', key: 'agendamento' },
    { title: 'Periodicidade', dataIndex: 'periodicidade', key: 'periodicidade' },
    { title: 'Última Execução', dataIndex: 'lastRun', key: 'lastRun' },
    { title: 'Próxima Execução', dataIndex: 'nextExecution', key: 'nextExecution' },
    { title: 'Status', dataIndex: 'currentStatus', key: 'currentStatus' },
    {
      title: 'Ações',
      key: 'actions',
      render: (_, record) => {
        const task = tasks.find(dicionario => dicionario.id === record.id);
        const items = [
          {
            key: 'run',
            label: 'Executar Agora',
            icon: <PlayCircleOutlined />,
          },
          {
            key: 'break',
            label: 'Parar Execução',
            icon: <PauseCircleOutlined />,
          },
          {
            key: 'disable',
            label: 'Ativar/Desativar',
            icon: <PoweroffOutlined />,
          },
          {
            key: 'history',
            label: 'Ver Histórico',
            icon: <HistoryOutlined />,
          },
          {
            key: 'dependencies',
            label: 'Ver Dependências',
            icon: <HistoryOutlined />,
          },
          {
            key: 'edit',
            label: 'Editar Tarefa',
            icon: <EditOutlined />,
          },
          {
            key: 'delete',
            label: 'Excluir Tarefa',
            icon: <DeleteOutlined />,
          },
        ];
        
        const onMenuClick: MenuProps['onClick'] = (e) => {
          const { key } = e;
          switch (key) {
            case 'run':
              runJob(task.id, task.name);
              break;
            case 'break':
              breakJob(task.id, task.name);
              break;
            case 'disable':
              disableTask(task);
              break;
            case 'history':
              showModal(task.id);
              break;
            case 'dependencies':
              showDependenciesModal(task.id);
              break;
            case 'edit':
              editTask(task);
              break;
            case 'delete':
              deleteJob(task.id, task.name);
              break;
            default:
              break;
          }
        };
        return (
          <Dropdown menu={{ items, onClick: onMenuClick }} trigger={['click']}>
            <Button>
                Gerenciar
                <MoreOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <div style={{ display: 'block', width: '100vw' }}>
          <HeaderPrime showConfig={false} pageTitle="Prime Orchestrator​" />
          <Content>
            <OrquestradorContainer>
              <TableContainer>
                <StyledTable
                  dataSource={jobs}
                  columns={columns}
                  rowKey="id"
                  pagination={{ pageSize: 10 }}
                  scroll={{ x: '100%', y: 'calc(100vh - <altura_header>)' }}
                />
              </TableContainer>
            </OrquestradorContainer>
            <CustomModal title={modalTitle} visible={isModalVisible} onCancel={handleCancel}>
              {modalContent}
            </CustomModal>

            <CustomModal title="Log da Tarefa" visible={isLogModalVisible} onCancel={handleLogModalCancel}>
              <div style={{ whiteSpace: 'pre-wrap' }}>{logContent}</div>
            </CustomModal>
          </Content>
    </div>
  );
};

export default OrquestradorRPA;
